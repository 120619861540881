Menu {
  background: red;
}

.bm-item-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 30px;
  justify-content: flex-start;
}

.bm-item-list a + a {
  margin-top: 15px;
}

.bm-menu {
  background: #272727;
}

.bm-item-list a:after {
  content: "→";
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #777777;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Styling of overlay */
.bm-overlay {
  left: 0;
  top: 0;
}
