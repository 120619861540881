.swiper-slide a:after {
  content: none;
}

.swiper-slide {
  width: 90% !important;
  max-width: 600px;
}

@media only screen and (max-width: 600px) {
  .swiper-slide {
    width: 85% !important;
    margin-left: -3.5%;
  }
}

.swiper-slide h3 {
  margin-top: 15px;
}

.swiper-slide p {
  color: #777;
  white-space: pre-wrap;
}

figure {
  background: rgba(255, 255, 255, 0.11);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  max-width: 600px;
}

figure:hover > img {
  transform: scale(1.05);
}

figure img {
  height: 100%;
  max-height: 175px;
  width: 50%;
  transition: transform 0.2s ease-in-out;
}
