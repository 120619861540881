.content--centered {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
}

aside {
  border-left: 1px solid rgba(119, 119, 119, 0.5);
  padding-left: 25px;
}
