@font-face {
  font-family: "SF";
  src: url("../fonts/SF-UI-Display-Medium.woff");
  font-weight: 400;
}

@font-face {
  font-family: "SF";
  src: url("../fonts/SF-UI-Display-Bold.woff");
  font-weight: 700;
}

html {
  font-family: "SF" !important;
}

h1 {
  font-size: 48px !important;
  color: white !important;
  font-weight: 700 !important;
  margin-top: 50px !important;
  background: linear-gradient(-21deg, #384001, #93A603);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 3s ease-in-out infinite;
  -webkit-animation: gradient 3s ease-in-out infinite;
  background-size: 200% 200%;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 38px !important;
  }
}

h2 {
  color: white;
  font-size: 24px !important;
  font-weight: 700 !important;
  margin-top: 50px !important;
  margin-bottom: 10px !important;
}

h3 {
  color: white !important;
  font-size: 18px !important;
}

h4 {
  font-size: 14px !important;
}

h4 + h3 {
  margin-top: 5px !important;
}

a + h3 {
  margin-top: 15px !important;
}

a + h4 {
  margin-top: 25px !important;
}

h5 {
  color: white !important;
  font-size: 18px !important;
}

p {
  font-size: 16px !important;
}

p + p {
  margin-top: 15px !important;
}

a {
  transition: opacity 0.2s;
  color: white !important;
  opacity: 0.7;
  white-space: nowrap;
}

a:hover {
  opacity: 1;
}

a:after {
  content: " ↗";
  display: inline-block;
  margin-left: 5px;
  font-family: "SF";
  position: relative;
  top: 2px;
}

a.invalid {
  pointer-events: none;
}

a.invalid:after {
  content: "";
}

a.invalid:hover {
  opacity: 0.7;
}

.copyright {
  opacity: 0.5;
  margin-top: 50px;
  font-size: 12px !important;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 38px !important;
  }

  .copyright {
    max-width: 276px;
    line-height: 2;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
