.profile {
  border-radius: 100%;
  width: 125px;
  height: 125px;
}

nav {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-around;
}

nav.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
